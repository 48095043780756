
import type { PeripheralServiceConfig } from "../../shared/index";
import { PeripheralType, WebUsbPrinterService } from "../../shared/index";

import { UsbReceiptPrinter } from "./UsbReceiptPrinter";

export class UsbReceiptPrinterService extends WebUsbPrinterService<UsbReceiptPrinter> {
  constructor(config?: Partial<PeripheralServiceConfig>) {
    super(PeripheralType.receiptPrinter, 'UsbReceiptPrinterService', config);
  }

  createPrinter(device: USBDevice): UsbReceiptPrinter { 
    return new UsbReceiptPrinter(device) 
  }
}
