import type { PeripheralServiceConfig } from '../../shared/index';
import { PeripheralType } from '../../shared/index';
import { WebviewAppPeripheralData, WebviewAppPeripheralService } from '../../shared/webview-app-bridge/index';

import { WebviewAppLabelPrinter } from './WebviewAppLabelPrinter';

export class WebviewAppLabelPrinterService extends WebviewAppPeripheralService<WebviewAppLabelPrinter> {
  constructor(config?: Partial<PeripheralServiceConfig>) {
    super(
      PeripheralType.labelPrinter,
      {
        autoConnect: false,
        autoEnable: true,
        name: 'WebviewAppLabelPrinterService',
      },
      config,
    );
  }

  createDevice(info: WebviewAppPeripheralData): WebviewAppLabelPrinter {
    return new WebviewAppLabelPrinter(info)
  }
}
