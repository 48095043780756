import { Vendors } from "../../utils/vendor";
import { Peripheral } from "../shared/Peripheral";
import type { ConnectionType, PeripheralInfo } from "../shared/index";
import { PeripheralType } from "../shared/index";
import { ReceiptLanguage, ReceiptLanguages } from "./enums";
import { EscPrinterEncoder, StarPrinterEncoder } from "./encoders";
import { ReceiptPeripheralInfo } from "./types";
export abstract class ReceiptPrinter extends Peripheral {
  abstract print(bytes: Uint8Array): Promise<boolean>;
  abstract popCashDrawer(): Promise<boolean>;

  constructor(connectionType: ConnectionType) {
    super(PeripheralType.receiptPrinter, connectionType);
  }

  get encoder(): EscPrinterEncoder {
    if (
      this.vendorId &&
      Vendors.star.supported[PeripheralType.receiptPrinter].indexOf(
        this.vendorId
      ) !== -1
    ) {
      return new StarPrinterEncoder();
    }
    return new EscPrinterEncoder();
  }

  get info(): ReceiptPeripheralInfo {
    return {
      ...super.info,
      language: this.language,
    } as ReceiptPeripheralInfo;
  }

  get language(): ReceiptLanguage {
    if (this.vendorId) {
      if (
        Vendors.star.supported[PeripheralType.receiptPrinter].indexOf(
          this.vendorId
        ) !== -1
      ) {
        if (this.productId) {
          // McPrint 30/31L/31LB
          const mcPrint3Id = 71;

          // TSP100
          const tsp100Id = 3;

          if (this.productId == mcPrint3Id) {
            return ReceiptLanguages.starPrint;
          } else if (this.productId == tsp100Id) {
            return ReceiptLanguages.starGraphic;
          }
        }
      }
    }

    return ReceiptLanguages.esc;
  }
}
