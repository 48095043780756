import type { BufferConfiguration } from '../../../utils/InputBuffer';
import { InputBuffer } from '../../../utils/InputBuffer';
import { KeyboardEventRepeater } from '../../../utils/KeyboardEventRepeater';
import { HardwareService } from '../../HardwareService';
import { ConnectionType } from '../../shared/index';
import { Scale } from '../Scale';
import { ScaleMeasurementParser } from '../ScaleMeasurementParser';


export class KeyboardScale extends Scale {
  private _connected = false;
  private inputBuffer: InputBuffer<KeyboardEvent>;

  private parser = new ScaleMeasurementParser(HardwareService.logger);
  
  constructor(initiallyConnected: boolean, config?: Partial<BufferConfiguration>) {
    super(ConnectionType.keyboard);
    
    this._connected = initiallyConnected;
    
    this.inputBuffer = new InputBuffer<KeyboardEvent>({
      name: 'KeyboardScale',
      config: {
        maxAverageInterval: config?.maxAverageInterval || 80, // Windows serial through 232Key slowed below 50ms
        minItemCount: config?.minItemCount || 3, // requires at least 3 key events which accounts for a minimum of 1 digit and decimal
        timeout: config?.timeout,
      },
      onCandidateDetected: this.handleInputCandidate,
    });
  }

  get id(): string { return 'keyboard-scale'; }
  get isConnected(): boolean { return this._connected; }
  get name(): string { return 'Keyboard Scale'; }

  // must be arrow function to be bound to this
  private handleInputCandidate = (props: { items: KeyboardEvent[]} ) => {
    const measurement = this.parser.parseMeasurementFromKeyboardEvents(props.items);
    if (measurement) {
      this.emitMeasurementEvent(measurement);
    }
  }

// must be arrow function to be bound to this
  private handleKeyPress = (e: Event): void => {
    const event: KeyboardEvent = (e as CustomEvent).detail as KeyboardEvent;
    const forceCheck = this.parser.keyboardTerminatorCodes.includes(event.code);
    this.inputBuffer.push(event, forceCheck);
  }

  // must be arrow function to be bound to this
  private handleKeyUp = (e: Event): void => {
    const event: KeyboardEvent = (e as CustomEvent).detail as KeyboardEvent;
    
    if(!this.parser.keyboardTerminatorCodes.includes(event.code)) return;

    // trigger a check when a terminator code was detected
    this.inputBuffer.maybeEmitInputBufferAsCandidate();
  }

  async doConnect(): Promise<boolean> {
    if (this._connected) return true;

    KeyboardEventRepeater.instance.addEventListener('keypress', this.handleKeyPress);
    KeyboardEventRepeater.instance.addEventListener('keyup', this.handleKeyUp);

    this._connected = true;
    return true;
  }

  async doDisconnect(): Promise<boolean> {
    if (!this._connected) return true;
    
    KeyboardEventRepeater.instance.removeEventListener('keyup', this.handleKeyUp);
    KeyboardEventRepeater.instance.removeEventListener('keypress', this.handleKeyPress);
    
    this._connected = false;

    return true;
  }
}
