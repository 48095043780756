import { PeripheralServiceConfig, PeripheralType } from "@dutchie/capacitor-hardware";
import { Peripheral } from "../../index";
import { NativePeripheralService } from "../shared/NativePeripheralService";
import { NativeLabelPrinter } from "./NativeLabelPrinter";

export class NativeLabelPrinterService extends NativePeripheralService<NativeLabelPrinter> {
  constructor(config?: Partial<PeripheralServiceConfig>) {
    super(PeripheralType.labelPrinter, 'NativeLabelPrinterService', config)
  }
  
  async availableDevices(): Promise<NativeLabelPrinter[]> {
    const result = await Peripheral.searchLabelPrinters();
    return result.results.map((it) => new NativeLabelPrinter(it));
  }
}
