import { HardwareService } from '../HardwareService';
import type { PeripheralFilter, PeripheralSearchConfig } from '../shared/types';
import { usePeripherals } from '../shared/usePeripherals';

import type { ReceiptPrinter } from './ReceiptPrinter';

type ReceiptPrintersHookProps = {
  filter?: PeripheralFilter<ReceiptPrinter>;
  onDevicesAuthorized?: (devices: ReceiptPrinter[]) => void;
}

export type ReceiptPrintersHook = {
  receiptPrinters: ReceiptPrinter[];
  refresh: () => void;
  search: (props?: Partial<PeripheralSearchConfig>) => void;
}

export const useReceiptPrinters = (props?: ReceiptPrintersHookProps): ReceiptPrintersHook => {
  const result = usePeripherals<ReceiptPrinter>({
    service: HardwareService.receiptPrinter,
    filter: props?.filter,
    onDevicesAuthorized: props?.onDevicesAuthorized
  });

  return {
    receiptPrinters: result.devices,
    refresh: result.refresh,
    search: result.search
  }
}
