
import { HardwareService } from "../HardwareService";

import type { Peripheral } from "./Peripheral";
import type { PeripheralService } from "./PeripheralService";
import type { IPeripheralServiceManager } from "./interfaces";
import type { CustomEventListenerOrObject, DevicesAuthorizedEvent, DeviceStatusEvent, DevicesUpdatedEvent, PeripheralSearchConfig, PeripheralType, PeripheralTypeInfo } from "./types";
import { DevicePersistence, peripheralTypeName } from "./types";

// Store data about a device enabling us to detect state changes.
// The device object may contain pointers to objects like USB devices so a
// copy of the current connected state is used for comparison when updates occur
type PeripheralData<T extends Peripheral> = {
  device: T, 
  connected: boolean,
  serviceName: string,
}

// Track whether service is currently enable and use state to add or remove devices.
// This value is NOT persisted across loads and it is expected consuming apps will
// utilize the autoEnable setting to manage which services initialize on load.
export type ServiceState<T extends Peripheral> = {
  enabled: boolean,
  service: PeripheralService<T>,
}

// Handles device across multiple services for a specific peripheral type
export class PeripheralServiceManager<T extends Peripheral> implements IPeripheralServiceManager<T> {
  private type: PeripheralType;

  filterEnabled = true;

  constructor(type: PeripheralType) {
    this.type = type;
  }

  deviceById(id: string): T | undefined { return this.grid.get(id)?.device; }

  // Keep a list of all devices in an easily accessible map
  private grid: Map<string, PeripheralData<T>> = new Map();
  private get gridValues(): PeripheralData<T>[] { return Array.from(this.grid.values()) }

  // Custom events specific to this peripheral type across all services
  events: EventTarget = new EventTarget();

  // Registered service
  private serviceState: Record<string, ServiceState<T>> = {};
  get state(): ServiceState<T>[] {
    return Object.values(this.serviceState);
  }

  private get enabledServices(): PeripheralService<T>[] {
    return this.state.filter(it => it.enabled).map(it => it.service);
  }

  private get disabledServices(): PeripheralService<T>[] {
    return this.state.filter(it => !it.enabled).map(it => it.service);
  }

  get devices(): T[] { return this.gridValues.map(it => it.device) };
  get hasDevices(): boolean { return this.grid.keys.length > 0 }
  get typeName(): string { return peripheralTypeName(this.type); }
  get peripheralType(): PeripheralTypeInfo { return { id: this.type, name: this.typeName } }

  //#region Private Methods
  // Emit events 
  private emitDeviceStatus(device: Peripheral, source?: string): void {
    const data: DeviceStatusEvent = { device: device.info, peripheralType: this.peripheralType };
    HardwareService.logger.d(`Emit ${'device-status'} for ${this.typeName}`,  { from: source, ...data });
    this.events.dispatchEvent(new CustomEvent('device-status', { detail: data }));
  }

  private emitDevicesAuthorized(devices: Peripheral[]): void {
    const details: DevicesAuthorizedEvent = { peripheralType: this.peripheralType, devices: devices.map(it => it.info) };
    HardwareService.logger.debug({
      message: 'Emit devices-authorized',
      details: details,
    })
    this.events.dispatchEvent(new CustomEvent('devices-authorized', { detail: details }));
  }
  
  private emitDevicesUpdated(): void {
    const details: DevicesUpdatedEvent = { devices: this.devices.map(it => it.info), peripheralType: this.peripheralType };
    HardwareService.logger.debug({
      message: `Emit devices-updated`,
      details: details,
    })
    this.events.dispatchEvent(new CustomEvent('devices-updated', { detail: details }));
  }

  private removeDeviceAndEmitEvent(device: Peripheral, emit: boolean): boolean {
    const item = this.grid.get(device.id);
    if (!item) return false;

    item.device.dispose();

    if (emit) {
      this.emitDeviceStatus(item.device, 'removeDevice');
    }

    if (!this.grid.delete(item.device.id)) return false;

    if (emit) {
      this.emitDevicesUpdated();
    }
    return true;
  }

  private updateServiceResultsAndEmitEvent(devices: T[], service: PeripheralService<T>, remove = true) {
    let emit = false;

    const deviceList = devices.map<T>(device => {
      // Use existing value from grid if singleton
      if (device.persistence == DevicePersistence.singleton) {
        return this.gridValues.find(it => it.device.id === device.id)?.device ?? device;
      }
      // Use provided object as the new source of truth
      return device;
    })

    for (const device of deviceList) {
      let cached = this.grid.get(device.id);

      // add device
      if (!cached) {
        cached = { device, connected: device.isConnected, serviceName: service.name };
        this.grid.set(device.id, cached);

        // initialize instance only once when added to map
        device.initialize();

        if (service.config.autoConnect) {
          device.connect();
        }

        emit = true;
        continue;
      }

      // Update grid with the latest connection status. Singleton devices use cached device info as the source of truth.
      if ((device.persistence == DevicePersistence.singleton && cached.device.isConnected !== cached.connected)
        || (device.persistence == DevicePersistence.transient && device.isConnected !== cached.connected)
      ) {
        const updatedInfo = device.persistence == DevicePersistence.singleton
          ? { ...cached, connected: cached.device.isConnected } // use cached device's connection status
          : { ...cached, device, connected: device.isConnected }; // update cached device and connection status
        
        this.grid.set(device.id, updatedInfo);
        emit = true;
        this.emitDeviceStatus(device, 'updateServiceResults');
        continue;
      }
    }

    if (remove) {
      const currentDeviceIds = devices
        .map(it => it.id);

      const removeDevices = this.gridValues
        .filter(it => it.serviceName == service.name)
        .filter(it => !currentDeviceIds.includes(it.device.id));

      for (const removeDevice of removeDevices) {
        const removed = this.removeDeviceAndEmitEvent(removeDevice.device, false);
        if (removed) {
          emit = true;
        }
      }
    }

    if (emit) {
      this.emitDevicesUpdated();
    }
  }
  //#endregion

  addEventListener<T extends object, E = CustomEventListenerOrObject<T>>(
    type: 'devices-authorized' | 'devices-updated' | 'device-status' | string,
    callback: E
  ): void {
    this.events.addEventListener(type, callback as EventListenerOrEventListenerObject);
  }
  removeEventListener<T extends object, E = CustomEventListenerOrObject<T>>(
    type: 'devices-authorized' | 'devices-updated' | 'device-status' | string,
    callback: E
  ): void {
    this.events.removeEventListener(type, callback as EventListenerOrEventListenerObject);
  }

  dispose(): void {
    this.state.map(state => state.service).forEach(service => this.unregister(service));
  }

  getServiceNamed(name: string): PeripheralService<T> | undefined {
    return this.state.find(it => it.service.name === name)?.service;
  }

  // Update device in grid and emit event
  setDevice(device: T, serviceName: string): void {
    const service = this.getServiceNamed(serviceName);
    if (!service) return;

    // ensure there are changes
    const current = this.grid.get(device.id)?.device;
    if (current?.isConnected === device.isConnected) return;

    // Create updated list
    const devices: T[] = this.gridValues.filter(it => it.serviceName == serviceName).map(it => {
      if (it.device.id === device.id) {
        return device;
      }
      return it.device;
    });

    this.updateServiceResultsAndEmitEvent(devices, service);
  }

  setDevices(devices: T[], serviceName: string): void {
    const service = this.getServiceNamed(serviceName);
    if (!service) return;

    this.updateServiceResultsAndEmitEvent(devices, service);
  }

  async search(config?: Partial<PeripheralSearchConfig>): Promise<T[]> {
    for (const service of this.enabledServices) {
      try {
        HardwareService.logger.d(`Search devices`, { service: service.name, type: this.typeName });
        const { devices, newDevices } = await service.search(config);

        this.updateServiceResultsAndEmitEvent(devices, service, false);

        // If new devices were selected, emit an event to allow the user to handle them
        if (config?.requestNewDevices === true && newDevices.length) {
          this.emitDevicesAuthorized(newDevices);
        }

        // Authorize devices from unrecognized vendors
        if (config?.requestNewDevices === true && !this.filterEnabled && devices.length) {
          const vendorIds = service.vendorIds;
          const unsupportedVendorIds: number[] = devices.map(it => it.vendorId).filter(it => it && vendorIds.indexOf(it) === -1) as number[];
          unsupportedVendorIds.forEach(vendorId => service.authorizeVendor(vendorId));
        }
      }
      catch (e) {
        HardwareService.logger.e('Uncaught discovery', e)
      }
    }

    return this.devices.sort((a, b) => a.name.localeCompare(b.name));
  }

  // Resync devices against those currently reported as available by the service.
  // Dispose and remove devices no longer available.
  // Add and initialize new devices.
  async invalidate(serviceName: string): Promise<void> {
    // detect service
    const service = this.enabledServices.find(it => it.name == serviceName);
    if (!service) return;

    const { devices } = await service.search();
    this.updateServiceResultsAndEmitEvent(devices, service)
  }
  
  async invalidateDeviceId(id: string): Promise<void> {
    const serviceName = this.gridValues.find(it => it.device.id == id)?.serviceName;
    
    if(serviceName) {
      await this.invalidate(serviceName)
    }
  }

  async updateDevice(device: T): Promise<void> {
    const serviceName = this.gridValues.find(it => it.device.id == device.id)?.serviceName;
    if (!serviceName) return;

    this.setDevice(device, serviceName);
  }

  async reconnectService(serviceName: string): Promise<T[]> {
    const service = this.enabledServices.find(it => it.name == serviceName)
    if(!service) return [];

    const { devices } = await service.search()
    this.updateServiceResultsAndEmitEvent(devices, service)
    return this.devices;
  }

  async register(...services: PeripheralService<T>[]): Promise<void> {

    const promises = services
      // filter out enabled services
      .filter((svc) => !this.enabledServices.some((it) => it.name === svc.name))
      .map(async (service): Promise<boolean> => {
        if (!this.serviceState[service.name]) {
          this.serviceState[service.name] = { enabled: false, service: service };

          // Allow service to be registered in a disabled state while not preventing
          // subsequent registrations
          if(service.config.autoEnable === false) { return false; }
        }

        HardwareService.logger.d(`Register ${this.typeName} service ${service.name}`);
        const initialized = await service.initialize();
        const enabled = initialized && service.isSupported;
        this.serviceState[service.name].enabled = enabled;

        await this.reconnectService(service.name);

        this.events.dispatchEvent(new CustomEvent('registered-service', { detail: { service, enabled } }));

        return enabled;
      });

    await Promise.all(promises);
  }

  // Removes device from grid and disposes
  removeDevice(device: Peripheral): boolean {
    return this.removeDeviceAndEmitEvent(device, true);
  }
  
  serviceNameById(id: string): string | undefined { return this.grid.get(id)?.serviceName; }

  unregister(...services: PeripheralService<T>[]): void {
    for (const service of services) {
      if (!this.enabledServices.some((svc) => service.name === svc.name)) {
        continue;
      }

      // remove all devices
      this.gridValues
        .filter(it => it.serviceName == service.name)
        .forEach(it => this.removeDevice(it.device))

      // dispose service
      service.dispose();

      this.serviceState[service.name].enabled = false;

      this.events.dispatchEvent(new CustomEvent('unregistered-service', { detail: { name: service.name, enabled: false } }));
      HardwareService.logger.d(`Unregister ${this.typeName} service ${service.name}`);
    }
  }
}
