import { InputBuffer } from "../../../utils/InputBuffer";
import { HardwareService } from "../../HardwareService";
import type { Peripheral } from "../Peripheral";
import { PeripheralService } from "../PeripheralService";
import type { PeripheralServiceConfig, PeripheralType } from "../types";
import { WebApi } from "../types";

export abstract class WebSerialService<T extends Peripheral> extends PeripheralService<T> {
  constructor(peripheralType: PeripheralType, defaultConfig: PeripheralServiceConfig, config?: Partial<PeripheralServiceConfig>) {
    super(peripheralType, defaultConfig, config);
  }

  abstract createPort(port: SerialPort): T;

  abstract onInvalidate(): void;
  
  get isSupported(): boolean { return !!navigator.serial }
  
  // A connection event is fired for every known serial device. If disconnecting a hub
  // this could fire multiple times. A buffer is used to prevent multiple invalidations
  // within a short period of time.
  private connectionEventBuffer = new InputBuffer<Event>({
    name: 'WebSerialServiceHelper',
    onCandidateDetected: () => this.onInvalidate(),
  });

  // must be arrow function to be bound to this
  private handleConnectionChanged = (event: Event): void => {
    this.connectionEventBuffer.push(event);
  }

  async availableDevices(): Promise<T[]> {
    try {
      const ports = await WebApi.serial.getPorts();
      return ports
        .filter(port => this.vendorIds.indexOf(port.getInfo().usbVendorId ?? 0) !== -1)
        .map(port => this.createPort(port));
    }
    catch (e) {
      HardwareService.logger.error(e);
    }
    return [];
  }
  
  async authorizeNewDevices(): Promise<T[]> {
    try {
      const filters: SerialPortFilter[] = this.isFilterEnabled ? this.vendorIds.map(id => {
        return {
          usbVendorId: id
        } as SerialPortFilter
      }) : [];
      const port = await WebApi.serial.requestPort({ filters })
      if (port) {
        return [this.createPort(port)];
      }
    }
    catch (e) {
      if (!(e instanceof DOMException && e.name === 'NotFoundError')) {
        HardwareService.logger.error(e);
      }
    }
    return []
  }

  detachEventListeners(): void {
    WebApi.serial.removeEventListener('connect', this.handleConnectionChanged);
    WebApi.serial.removeEventListener('disconnect', this.handleConnectionChanged);
  }
  
  attachEventListeners(): void {
    WebApi.serial.addEventListener('connect', this.handleConnectionChanged);
    WebApi.serial.addEventListener('disconnect', this.handleConnectionChanged);
  }
}
