import { HardwareService } from "../HardwareService";
import type { PeripheralFilter, PeripheralSearchConfig } from "../shared/types";
import { usePeripherals } from "../shared/usePeripherals";

import type { LabelPrinter } from "./LabelPrinter";

type LabelPrintersHookProps = {
  filter?: PeripheralFilter<LabelPrinter>;
  onDevicesAuthorized?: (devices: LabelPrinter[]) => void;
}

export type LabelPrintersHook = {
  labelPrinters: LabelPrinter[];
  refresh: () => void;
  search: (props?: Partial<PeripheralSearchConfig>) => void;
}

export const useLabelPrinters = (props?: LabelPrintersHookProps): LabelPrintersHook => {
  const result = usePeripherals<LabelPrinter>({
    service: HardwareService.labelPrinter,
    filter: props?.filter,
    onDevicesAuthorized: props?.onDevicesAuthorized,
  });

  return {
    labelPrinters: result.devices,
    refresh: result.refresh,
    search: result.search,
  }
}
