import { KeyboardEventRepeater } from "../utils/KeyboardEventRepeater";
import { HardwareLogger } from "../utils/logger";

import type { LabelPrinter } from "./label-printer/index";
import type { ReceiptPrinter } from "./receipt-printer/index";
import type { Scale } from "./scale/index";
import type { Scanner } from "./scanner/index";
import type { Peripheral } from "./shared/index";
import { PeripheralServiceManager, PeripheralType } from "./shared/index";

export const HardwareService = {
  dispose: (): void => {
    HardwareService.labelPrinter.dispose();
    HardwareService.receiptPrinter.dispose();
    HardwareService.scale.dispose();
    HardwareService.scanner.dispose();
    KeyboardEventRepeater.disposeInstance();
  },
  managerByType: (type: PeripheralType): PeripheralServiceManager<Peripheral> => {
    switch (type) {
      case PeripheralType.labelPrinter:
        return HardwareService.labelPrinter;
      case PeripheralType.receiptPrinter:
        return HardwareService.receiptPrinter;
      case PeripheralType.scale:
        return HardwareService.scale;
      case PeripheralType.scanner:
        return HardwareService.scanner;
    }
  },
  labelPrinter: new PeripheralServiceManager<LabelPrinter>(PeripheralType.labelPrinter),
  logger: new HardwareLogger(),
  receiptPrinter: new PeripheralServiceManager<ReceiptPrinter>(PeripheralType.receiptPrinter),
  scale: new PeripheralServiceManager<Scale>(PeripheralType.scale),
  scanner: new PeripheralServiceManager<Scanner>(PeripheralType.scanner),
}
