import { storage, vendorIdsByType } from "../../utils/index";
import { HardwareService } from "../HardwareService";

import type { Peripheral } from "./Peripheral";
import type { PeripheralServiceManager } from "./PeripheralServiceManager";
import type { IPeripheralService } from "./interfaces";
import type { PeripheralSearchConfig, PeripheralSearchResponse, PeripheralServiceConfig } from "./types";
import { PeripheralType, peripheralTypeName } from "./types";

export abstract class PeripheralService<T extends Peripheral> implements IPeripheralService<T> {  
  config: PeripheralServiceConfig;
  peripheralType: PeripheralType;

  constructor(peripheralType: PeripheralType, defaultConfig: PeripheralServiceConfig, userConfig?: Partial<PeripheralServiceConfig>) {
    this.peripheralType = peripheralType;
    this.config = {
      autoConnect: userConfig?.autoConnect ?? defaultConfig.autoConnect,
      autoEnable: userConfig?.autoEnable !== undefined ? userConfig.autoEnable : defaultConfig.autoEnable,
      name: userConfig?.name ?? defaultConfig.name,
    };
  }
  
  get isFilterEnabled(): boolean { 
    return this.serviceManager.filterEnabled;
   }
  abstract isSupported: boolean;

  get name(): string { return this.config.name }
  get typeName(): string { return peripheralTypeName(this.peripheralType); }

  // Returns the standard list of vendor ids for the peripheral type. This list is
  // not exhaustive and can be overridden if a service requires more or fewer ids.
  get vendorIds(): number[] { 
    return [
    ...this.supportedVendorIds,
    ...this.authorizedVendorIds
  ]; }

  private get authorizedVendorIdsKey(): string { return `${this.name.toLowerCase().replace(' ', '')}.vendorIds` }
  private get authorizedVendorIds(): number[] {
    return storage.getObject<number[]>(this.authorizedVendorIdsKey) ?? [];
  }

  private get supportedVendorIds(): number[] { return vendorIdsByType(this.peripheralType); }

  authorizeVendor(vendorId: number) {
    if (this.vendorIds.indexOf(vendorId) !== -1) {
      return;
    }
    storage.setObject(this.authorizedVendorIdsKey, [...this.authorizedVendorIds, vendorId]);
  }

  // Choose the correct service manager based on the peripheral type
  protected get serviceManager(): PeripheralServiceManager<any> {
    switch(this.peripheralType) {
      case PeripheralType.labelPrinter: return HardwareService.labelPrinter;
      case PeripheralType.receiptPrinter: return HardwareService.receiptPrinter;
      case PeripheralType.scale: return HardwareService.scale;
      case PeripheralType.scanner: return HardwareService.scanner;
    }
  }

  // Return all known devices.
  protected abstract availableDevices(): Promise<T[]>

  // Manage listeners for events that impact a service's capability or device list.
  protected attachEventListeners(): void { /* implementation not required */ }
  protected detachEventListeners(): void { /* implementation not required */ }
  
  // This method is only implemented when the service requires an additional step for 
  // initially permitting devices. This is used by WebUSB to request device permission
  // the first time which opens a separate window. Separating the logic enables the UI to 
  // choose when to prompt for new devices and when to refresh from an existing list.
  protected authorizeNewDevices(): Promise<T[]> { return Promise.resolve([]); }
  
  // Called when the service is being initialized and enables the service to wait for
  // any necessary data before it begins executing searches.
  protected waitUntilReady(): Promise<boolean> { return Promise.resolve(true); }

  // Called when manager unregisters this service and removes it from the list
  dispose(): void {
    if(!this.isSupported) return;
    this.detachEventListeners();
  }

  // Called when manager registers this service and adds it to the list
  async initialize(): Promise<boolean> {
    const ready = await this.waitUntilReady();
    if(!ready || !this.isSupported) return false;
    
    this.attachEventListeners();
    return true;
  }
  
  protected invalidate() : void {
    this.serviceManager.invalidate(this.name);
  }

  async search(config?: Partial<PeripheralSearchConfig>): Promise<PeripheralSearchResponse<T>> {
    const requestNewDevices = async () => {
      if (config?.requestNewDevices === true) {
        return await this.authorizeNewDevices();
      }
      return [];
    }

    let [availableDevices, newDevices] = await Promise.all([
      this.availableDevices(),
      requestNewDevices(),
    ]);

    const updatedDevices = [ ...availableDevices, ...newDevices ];

    return {
      devices: updatedDevices,
      newDevices
    }
  }
}
