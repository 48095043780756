import { Queue } from 'async-await-queue';

import { HardwareService } from "../../HardwareService";
import { PeripheralMetadata } from '../types';

export class WebUsbPrinterHelper {
  private device: USBDevice;

  constructor(device: USBDevice) {
    this.device = device;
  }

  get id(): string { return `usb-${this.device.vendorId}-${this.device.productId}-${this.device.productName}`; }
  get isConnected(): boolean { return this.device.opened }
  get metadata(): PeripheralMetadata {
    return {
      model: this.device.productName,
      serialNumber: this.device.serialNumber,
      productId: this.device.productId,
      vendorId: this.device.vendorId,
    }
  }
  /** @deprecated use metadata.productId */
  get productId(): number { return this.device.productId }
  /** @deprecated use metdata.vendorId */
  get vendorId(): number { return this.device.vendorId }

  //#region Private Methods
  private async claimEndpoint(direction: 'in' | 'out'): Promise<USBEndpoint | undefined> {
    for (const configuration of this.device.configurations) {
      try {
        for (const iface of configuration.interfaces) {
          try {
            for (const alt of iface.alternates) {
              for (const endpoint of alt.endpoints) {
                if (endpoint.direction === direction) {
                  try {
                    this.device.reset
                    await this.device.selectConfiguration(configuration.configurationValue)
                    await this.device.claimInterface(iface.interfaceNumber)
                    return endpoint
                  }
                  catch (e) {
                    HardwareService.logger.error(e)
                  }
                }
              }
            }
          }
          catch (e) {
            HardwareService.logger.error(e)
          }
        }
      }
      catch (e) {
        HardwareService.logger.error(e)
      }
    }

    return undefined
  }
  //#endregion

  async connect(): Promise<boolean> {
    try {
      if (this.device.opened) return true;
      await this.device.open();
      return true;
    }
    catch (e) {
      HardwareService.logger.error(e);
    }
    return false;
  }

  async disconnect(): Promise<boolean> {
    try {
      if (!this.device.opened) return true;
      await this.device.close();
      return true;
    }
    catch (e) {
      HardwareService.logger.error(e);
    }
    return false;
  }

  async revokePermission(): Promise<boolean> {
    // Release interfaces first. Do not disconnect or the device will not be forgotten.
    const claimedInterfaces: USBInterface[] = this.device.configuration?.interfaces?.filter(it => it.claimed) ?? [];

    for (const iface of claimedInterfaces) {
      try {
        await this.device.releaseInterface(iface.interfaceNumber);
      }
      catch (e) {
        HardwareService.logger.error(e);
      }
    }

    await this.device.forget();
    return true;
  }

  private writeQueue = new Queue()
  async write(bytes: Uint8Array): Promise<boolean> {
    const me = Symbol();
    
    try {
      await this.writeQueue.wait(me);

      const wasConnected = this.isConnected;
      if(!wasConnected) {
        await this.connect();
      }
  
      const endpoint = await this.claimEndpoint('out');
      if (endpoint) {
        try {
          await this.device.transferOut(endpoint.endpointNumber, bytes);
          return true;
        }
        catch (e) {
          HardwareService.logger.e('Failed transferring data to endpoint', e);
        }
        finally {
          if(!wasConnected) {
            await this.disconnect();
          }
        }
      }
  
      return false
    }
    finally {
      this.writeQueue.end(me);
    }
  }
}
