import { ConnectionType, PeripheralMetadata, Scale, ScaleMeasurement } from '@dutchie/capacitor-hardware';
import { Peripheral, Scale as PeripheralPackageScale, ScaleMeasurementPayload } from '../../index';

export class NativeScale extends Scale {
  private device: PeripheralPackageScale;

  constructor(device: PeripheralPackageScale) {
    super(ConnectionType.usb);
    this.device = device;
  }

  get id(): string { return this.device.id; }
  get isConnected(): boolean { return this.device.connected; }
  get metadata(): PeripheralMetadata { 
    return {
      vendorId: this.device.vendor.id
    };
  }
  get name(): string { return this.device.name; }
  get peripheralData(): PeripheralPackageScale { return this.device as PeripheralPackageScale }
  get vendorId(): number { return this.device.vendor.id }

  private handleDataRef = this.handleData.bind(this);
  private handleData(event: Event) {
    const payload = (event as any) as ScaleMeasurementPayload;
    if (payload.scale.id !== this.device.id) return;

    // Payload from native events exactly matches the enum values in the hardware plugin.
    const measurement: ScaleMeasurement = {
      value: payload.value,
      header: payload.header,
      unit: payload.unit,
    }
    this.emitMeasurementEvent(measurement);
  }

  attachEventListeners(): void {
    window.addEventListener('onScaleData', this.handleDataRef);
  }
  
  detachEventListeners(): void {
    window.removeEventListener('onScaleData', this.handleDataRef);
  }

  async doConnect(): Promise<boolean> {
    const result = await Peripheral.connectScale({ id: this.id });
    return result.success;
  }

  async doDisconnect(): Promise<boolean> {
    const result = await Peripheral.disconnectScale({ id: this.id });
    return result.success;
  }
}
