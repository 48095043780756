import { ConnectionType, DevicePersistence, PeripheralMetadata } from "../../shared/index";
import { WebUsbPrinterHelper } from "../../shared/web-usb/index";
import { LabelPrinter } from "../LabelPrinter";

export class UsbLabelPrinter extends LabelPrinter {

  private helper: WebUsbPrinterHelper;
  constructor(device: USBDevice) {
    super(ConnectionType.usb);
    this.helper = new WebUsbPrinterHelper(device);
  }

  get id(): string { return this.helper.id; }
  get isConnected(): boolean { return this.helper.isConnected; }
  get metadata(): PeripheralMetadata { return this.helper.metadata; }
  get persistence() { return DevicePersistence.singleton; }
  get productId(): number | undefined { return this.helper.productId; }
  get vendorId(): number | undefined { return this.helper.vendorId; }

  doConnect(): Promise<boolean> {
    return this.helper.connect();
  }

  doDisconnect(): Promise<boolean> {
    return this.helper.disconnect();
  }

  async doRevokePermission(): Promise<boolean> {
    return this.helper.revokePermission();
  }

  async print(bytes: Uint8Array): Promise<boolean> {
    return this.helper.write(bytes);
  }
}
