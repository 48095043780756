import { v4 } from "uuid";

import { HardwareService } from "../../HardwareService";
import type { ScaleMeasurement } from "../../scale";
import type { BarcodeData } from "../../scanner";
import { Completer } from "../Completer";
import type { Peripheral } from "../Peripheral";
import type { PeripheralServiceManager } from "../PeripheralServiceManager";
import { PeripheralSearchConfig, PeripheralType } from "../types";
import { webviewNamespace } from "./constants";

import { WebviewAppPeripheralService } from "./WebviewAppPeripheralService";
import type {
  BridgeError,
  BridgeEvent,
  BridgeResponse,
  ConnectionStatusResponse,
  FlutterJavascriptChannel,
  PrintJobStatusResponse,
  WebviewAppBarcodePayload,
  WebviewAppMeasurementPayload,
  WebviewAppPeripheralData,
} from "./types";

/**
 * Interface to communicate with dart code in the webview app.
 */
class WebviewAppBridge {
  private requestPromises: Record<string, Completer<any>> = {};

  constructor() {
    window.addEventListener(
      `on${webviewNamespace}Response`,
      this.handleResponse
    );
    window.addEventListener(`on${webviewNamespace}Error`, this.handleError);
    window.addEventListener(`on${webviewNamespace}Event`, this.handleEvent);
  }

  // Checks for the global object the Flutter webview creates to indicate we're running in the app
  private webview = (window as any)[webviewNamespace] as
    | FlutterJavascriptChannel
    | undefined;

  private handleError = (event: Event) => {
    const detail = (event as CustomEvent<BridgeError>).detail;
    const { id, message } = detail;
    HardwareService.logger.d(`ts bridge error: ${message} - ${id}`, detail);
    
    if (id) {
      this.requestPromises[id]?.error(message);
      delete this.requestPromises[id];
    }
  };

  private serviceManagerFor(
    type: PeripheralType
  ): PeripheralServiceManager<Peripheral> {
    switch (type) {
      case PeripheralType.labelPrinter:
        return HardwareService.labelPrinter;
      case PeripheralType.receiptPrinter:
        return HardwareService.receiptPrinter;
      case PeripheralType.scale:
        return HardwareService.scale;
      case PeripheralType.scanner:
        return HardwareService.scanner;
    }
  }

  private getServiceName(type: PeripheralType): string {
    switch (type) {
      case PeripheralType.labelPrinter:
        return `${webviewNamespace}LabelPrinterService`;
      case PeripheralType.receiptPrinter:
        return `${webviewNamespace}ReceiptPrinterService`;
      case PeripheralType.scale:
        return `${webviewNamespace}ScaleService`;
      case PeripheralType.scanner:
        return `${webviewNamespace}ScannerService`;
    }
  }

  // Hanle all messaging set from the webview app
  private handleEvent = async (event: Event) => {
    const detail = (event as CustomEvent<BridgeEvent>).detail;
    if (!detail.data) return;

    const data = detail.data as any;

    switch (detail.name) {
      case "device-status": {
        const info = data.device as WebviewAppPeripheralData;
        if (!info) return;

        const serviceManager = this.serviceManagerFor(detail.peripheralTypeId);
        const serviceName = this.getServiceName(detail.peripheralTypeId);
        const service = serviceManager.getServiceNamed(serviceName);
        if (!service) return;

        if (service instanceof WebviewAppPeripheralService) {
          // Update this device in service manager
          const device = service.createDevice(info);
          serviceManager.setDevice(device, service.name);
        }

        break;
      }
      case "devices-updated": {
        const devicesInfo = data.devices as WebviewAppPeripheralData[];
        if (!devicesInfo) return;
        this.updateServiceManagerWithDeviceInfo(detail.peripheralTypeId, devicesInfo);
        break;
      }
    }

    // data events
    if (detail.eventTypeId == 2) {
      if (
        detail.peripheralTypeId === PeripheralType.scale &&
        detail.name === "measurement"
      ) {
        const measurement = (detail.data as any)
          .measurement as WebviewAppMeasurementPayload;
        const measurementData: ScaleMeasurement = {
          header: measurement.header,
          value: measurement.value,
          unit: measurement.unit,
        };
        HardwareService.scale
          .deviceById(measurement.scale.id)
          ?.emitMeasurementEvent(measurementData);
      } else if (
        detail.peripheralTypeId === PeripheralType.scanner &&
        detail.name === "barcode"
      ) {
        const barcode = (detail.data as any)
          .barcode as WebviewAppBarcodePayload;
        const barcodeData: BarcodeData = {
          bytes: new Uint8Array(barcode.bytes),
          text: barcode.string,
        };
        HardwareService.scanner.deviceById(barcode.scanner.id)?.emitBarcodeEvent(barcodeData);
      }
    }
  };

  private updateServiceManagerWithDeviceInfo(peripheralType: PeripheralType, devicesInfo: WebviewAppPeripheralData[]) {
      // Update manager with the provided device list
      const serviceManager = this.serviceManagerFor(peripheralType);
      const serviceName = this.getServiceName(peripheralType);
      const service = serviceManager.getServiceNamed(serviceName);
      if (!service) return;

      if (service instanceof WebviewAppPeripheralService) {
        const devices = devicesInfo.map((info) => {
          return service.createDevice(info);
        });
        serviceManager.setDevices(devices, service.name);
      }
  }

  // To support round-trip requests, all requests contain a unique id and completes the promise whenever we receive a response matching the id.
  private handleResponse = (event: Event) => {
    const { id, method, data } = (event as CustomEvent<BridgeResponse>).detail;
    this.requestPromises[id]?.complete(data);
    delete this.requestPromises[id];
  };

  async connect(peripheralType: PeripheralType, id: string) {
    const result = await this.postMessage<ConnectionStatusResponse>("connect", {
      id,
      peripheralType,
    });
    return result?.success === true;
  }

  async disconnect(peripheralType: PeripheralType, id: string) {
    const result = await this.postMessage<ConnectionStatusResponse>(
      "disconnect",
      { id, peripheralType }
    );
    return result?.success === true;
  }

  async openCashDrawer(peripheralType: PeripheralType, id: string) {
    const result = await this.postMessage<PrintJobStatusResponse>("openCashDrawer", {
      id,
      peripheralType,
    });
    return result?.success === true;
  }

  async print(peripheralType: PeripheralType, id: string, bytes: Uint8Array) {
    const result = await this.postMessage<PrintJobStatusResponse>("print", {
      id,
      bytes: Array.from(bytes),
      peripheralType,
    });
    return result?.success === true;
  }

  async search(
    peripheralType: PeripheralType,
    config?: Partial<PeripheralSearchConfig>
  ): Promise<WebviewAppPeripheralData[]> {
    const result = await this.postMessage<WebviewAppPeripheralData[]>(
      "search",
      { peripheralType, config }
    );
    return result ?? [];
  }

  async sync(peripheralType: PeripheralType) {
    // Get current device list without initiating a search
    const result = await this.postMessage<WebviewAppPeripheralData[]>("cache", { peripheralType });
    this.updateServiceManagerWithDeviceInfo(peripheralType, result ? result : []);
  }

  async waitUntilReady(name: string): Promise<boolean> {
    if (this.webview) {
      return true;
    }

    return new Promise<boolean>((resolve) => {
      const timer = setTimeout(() => {
        HardwareService.logger.warn({
          message: `${webviewNamespace}Bridge not ready: waiting for ${name}`,
        });
        resolve(false);
      }, 10000);
      const checkReady = () => {
        if (this.webview) {
          clearTimeout(webviewTimer);
          clearTimeout(timer);

          resolve(true);
        }
      };

      checkReady();
      const webviewTimer = setInterval(() => {
        // check if webview namespace is available
        checkReady();
      }, 50);
    });
  }

  private postMessage = async <Result = unknown>(
    method: string,
    data: unknown
  ): Promise<Result | undefined> => {
    const id = v4();
    const json = JSON.stringify({ id, method, data });

    let result: Result | undefined;
    if (this.webview) {
      const completer = new Completer<Result>();
      this.requestPromises[id] = completer;
      this.webview.postMessage(json);
      result = await completer.promise;
    } else {
      result = undefined;
    }

    return result;
  };
}

export const webviewAppBridge = new WebviewAppBridge();
