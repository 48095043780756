import { InputBuffer } from "../../../utils/InputBuffer";
import { HardwareService } from "../../HardwareService";
import { ConnectionType, DevicePersistence, PeripheralMetadata } from "../../shared/types";
import { Scanner } from "../Scanner";

export class HidScanner extends Scanner {
  private device: HIDDevice;
  private inputBuffer: InputBuffer<HIDInputReportEvent>;

  constructor(device: HIDDevice) {
    super(ConnectionType.hid);
    this.device = device;

    this.inputBuffer = new InputBuffer<HIDInputReportEvent>({
      name: 'HidScanner',
      onCandidateDetected: this.onCandidateDetected
    });
  }

  static id(device: HIDDevice): string { return `${device.vendorId}-${device.productId}-${device.productName}`; }
  get id(): string { return HidScanner.id(this.device); }
  get isConnected(): boolean { return this.device.opened; }
  get metadata(): PeripheralMetadata { 
    return {
      model: this.device.productName,
      productId: this.device.productId,
      vendorId: this.device.vendorId,
    } 
  }
  get persistence() { return DevicePersistence.singleton; }
  get productId(): number { return this.device.productId; }
  get vendorId(): number { return this.device.vendorId; }

  //#region Private Methods
  // must be arrow function to be bound to this
  private handleInputReport = async (event: HIDInputReportEvent): Promise<void> => {
    // filter out events from other devices
    if (!this.isThisDevice(event.device)) return;

    if (event.data.byteLength > 4) {
      // if last byte is a 0 then this should be the last report in the sequence
      const forceCheck = event.data.getInt8(event.data.byteLength - 1) === 0;
      this.inputBuffer.push(event, forceCheck);
    }
  }

  private isThisDevice(device: HIDDevice): boolean {
    return this.device.vendorId === device.vendorId && this.device.productId === device.productId;
  }

  // Combine input reports into a single byte array taking vendor specific logic into account
  // and emit the combined barcode
  private onCandidateDetected = ({ items }: { items: HIDInputReportEvent[] }): void => {
    const modified = items.map((item: HIDInputReportEvent) => {
      const { data, device } = item;
      const length = data.byteLength;

      const itemBytes: number[] = [];
      for (let i = 0; i < length; i++) {
        itemBytes.push(data.getUint8(i));
      }

      // Handle vendor specific logic
      if (device.vendorId === 1504) {
        // remove chars until after the first NUL (0) char
        const index = itemBytes.indexOf(0);
        if (index !== -1) {
          itemBytes.splice(0, index + 1);
        }

        // remove 16, [x], 11 from the end of the array if it exists.
        // last[1] has been inconsistent in testing, but other two chars are consistent.
        // the last report will not have these bytes.
        const last = itemBytes.slice(-3);
        if (last[0] === 16 && last[2] === 11) {
          itemBytes.splice(-3);
        }

        // any remaining NUL chars indicate end of the report.
        const lastIndex = itemBytes.indexOf(0);
        if (lastIndex !== -1) {
          itemBytes.splice(lastIndex);
        }
      }

      return itemBytes;
    });

    const combined = modified.reduce((acc, val) => acc.concat(val), []);
    const bytes = Uint8Array.from(combined);

    this.emitBarcodeEvent({ bytes, text: new TextDecoder().decode(bytes), });
  }
  //#endregion

  attachEventListeners(): void {
    this.device.addEventListener('inputreport', this.handleInputReport);
  }

  detachEventListeners(): void {
    this.device.removeEventListener('inputreport', this.handleInputReport);
  }

  async doConnect(): Promise<boolean> {
    try {
      if (this.device.opened) return true;

      await this.device.open();
      return true;
    }
    catch (e) {
      HardwareService.logger.error(e);
    }
    return false;
  }

  async doDisconnect(): Promise<boolean> {
    try {
      if (!this.device.opened) return true;

      await this.device.close();
      return true;
    }
    catch (e) {
      HardwareService.logger.error(e);
    }
    return false;
  }

  async revokePermission(): Promise<boolean> {
    await this.device.forget();
    HardwareService.scanner.removeDevice(this);
    return true;
  }
}
