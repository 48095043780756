import { PeripheralMetadata, PeripheralType } from '../../shared/index';
import { WebviewAppPeripheralData, webviewAppBridge } from '../../shared/webview-app-bridge/index';
import { LabelPrinter } from '../LabelPrinter';

export class WebviewAppLabelPrinter extends LabelPrinter {
  private device: WebviewAppPeripheralData;
  constructor(device: WebviewAppPeripheralData) {
    super(device.connectionType);
    this.device = device;
  }

  get id(): string {
    return this.device.id;
  }
  get isConnected(): boolean {
    return this.device.connected;
  }
  get metadata(): PeripheralMetadata {
    return this.device.metadata;
  }
  get name(): string {
    return this.device.name;
  }

  private async write(bytes: Uint8Array): Promise<boolean> {
    return webviewAppBridge.print(PeripheralType.labelPrinter, this.id, bytes);
  }

  async doConnect(): Promise<boolean> {
    return webviewAppBridge.connect(PeripheralType.labelPrinter, this.id);
  }

  async doDisconnect(): Promise<boolean> {
    return webviewAppBridge.disconnect(PeripheralType.labelPrinter, this.id);
  }

  async print(bytes: Uint8Array): Promise<boolean> {
    return this.write(bytes);
  }
}
