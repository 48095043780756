import { ConnectionType, PeripheralMetadata, ReceiptPrinter } from '@dutchie/capacitor-hardware';
import { Peripheral, ReceiptPrinter as PeripheralPackageReceiptPrinter } from '../../index';

export class NativeReceiptPrinter extends ReceiptPrinter {
  private device: PeripheralPackageReceiptPrinter;

  constructor(device: PeripheralPackageReceiptPrinter) {
    super(ConnectionType.usb);
    this.device = device;
  }

  get id(): string { return this.device.id; }
  get isConnected(): boolean { return this.device.connected; }
  get metadata(): PeripheralMetadata { 
    return {
      vendorId: this.device.vendor.id
    };
  }
  get name(): string { return this.device.name; }
  get peripheralData(): PeripheralPackageReceiptPrinter { return this.device as PeripheralPackageReceiptPrinter }
  get vendorId(): number { return this.device.vendor.id }
  
  async doConnect(): Promise<boolean> {
    const result = await Peripheral.connectReceiptPrinter({ id: this.id });
    return result.success;
  }

  async doDisconnect(): Promise<boolean> {
    const result = await Peripheral.disconnectReceiptPrinter({ id: this.id });
    return result.success;
  }

  async popCashDrawer(): Promise<boolean> {
    const result = await Peripheral.receiptPrinterOpenCashDrawer({ id: this.id })
    return result.success;
  }

  async print(bytes: Uint8Array): Promise<boolean> {
    const result = await Peripheral.printReceipt({ id: this.id, bytes: Array.from(bytes) });
    return result.success;
  }
}
