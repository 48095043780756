import type { Peripheral, PeripheralInfo } from "./Peripheral";

export type PeripheralServiceConfig = {
  autoConnect: boolean; // default true
  autoEnable: boolean; // default true
  name: string; // default based on vendor id and peripheral type
}

export type PeripheralSearchConfig = {
  requestNewDevices: boolean;
};

export type PeripheralSearchResponse<T extends Peripheral> = {
  devices: T[];
  newDevices: T[];
};

export type PeripheralFilter<T extends Peripheral> = (device: T) => boolean;

export enum VendorId {
  star = 1,
  zebra = 2,
  epson = 3,
  and = 4
}

export enum PeripheralType {
  scanner = 1,
  labelPrinter = 2,
  receiptPrinter = 3,
  scale = 4,
}

export enum ConnectionType {
  hid = 1,
  lan = 2,
  serial = 3,
  usb = 4,
  bluetooth = 5,
  keyboard = 6,
  other = 7
}

export enum DevicePersistence {
  singleton,
  transient
}

export const peripheralTypeName = (type: PeripheralType): string => {
  switch (type) {
    case PeripheralType.labelPrinter: return 'Label Printer';
    case PeripheralType.receiptPrinter: return 'Receipt Printer';
    case PeripheralType.scale: return 'Scale';
    case PeripheralType.scanner: return 'Scanner';
  }
}

export const connectionTypeDisplayName = (type: ConnectionType): string => {
  switch (type) {
    case ConnectionType.bluetooth: return 'Bluetooth';
    case ConnectionType.hid: return 'HID';
    case ConnectionType.keyboard: return 'Keyboard';
    case ConnectionType.lan: return 'LAN';
    case ConnectionType.serial: return 'Serial';
    case ConnectionType.usb: return 'USB';
    case ConnectionType.other: return 'Other';
  }
}

export const WebApi = {
  bluetooth: (navigator as any).bluetooth as Bluetooth,
  hid: (navigator as any).hid as HID,
  serial: (navigator as any).serial as Serial,
  usb: (navigator as any).usb as USB,
}

export type DeviceStatusEvent = {
  peripheralType: PeripheralTypeInfo;
  device: PeripheralInfo;
}

export type DevicesAuthorizedEvent = {
  peripheralType: PeripheralTypeInfo;
  devices: PeripheralInfo[];
}

export type DevicesUpdatedEvent = {
  peripheralType: PeripheralTypeInfo;
  devices: PeripheralInfo[];
}

export type PeripheralTypeInfo = {
  id: PeripheralType;
  name: string;
};

export type PeripheralMetadata = {
  address?: string;
  serialNumber?: string;
  model?: string;

  // moved to metadata
  productId?: number;
  vendorId?: number;
}

/*
 * Types patterned after the TypeScript definitions for
 * - EventListener
 * - EventListenerObject
 * - EventListenerOrEventListenerObject
 */

export interface CustomEventListener<T> extends EventListener {
  (evt: Event | CustomEvent<T>): void;
}

export interface CustomEventListenerObject<T> extends EventListenerObject {
  handleEvent(evt: Event | CustomEvent<T>): void;
}

export type CustomEventListenerOrObject<T> = CustomEventListener<T> | CustomEventListenerObject<T>;