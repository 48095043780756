import { ConnectionType, PeripheralMetadata, Scanner } from '@dutchie/capacitor-hardware';
import { Peripheral, Scanner as PerpiheralPackageScanner } from '../../index';

export class NativeScanner extends Scanner {
  private device: PerpiheralPackageScanner;

  constructor(device: PerpiheralPackageScanner) {
    super(ConnectionType.usb);
    this.device = device;
  }

  get id(): string { return this.device.id; }
  get isConnected(): boolean { return this.device.connected; }
  get metadata(): PeripheralMetadata { 
    return {
      vendorId: this.device.vendor.id
    };
  }
  get name(): string { return this.device.name; }
  get peripheralData(): PerpiheralPackageScanner { return this.device as PerpiheralPackageScanner }
  get vendorId(): number { return this.device.vendor.id }

  attachEventListeners(): void {
    // onScannerData is attached in NativeScannerService
  }
  
  detachEventListeners(): void {
    // onScannerData is detached in NativeScannerService
  }

  async doConnect(): Promise<boolean> {
    const result = await Peripheral.connectScanner({ id: this.id });
    return result.success;
  }

  async doDisconnect(): Promise<boolean> {
    const result = await Peripheral.disconnectScanner({ id: this.id });
    return result.success;
  }
}
