
import { HardwareService } from "../../HardwareService";
import { ConnectionType, DevicePersistence, defaultSerialOptions } from "../../shared/index";
import { WebSerialHelper } from "../../shared/web-serial/WebSerialHelper";
import type { SerialConnectionOptions } from "../../shared/web-serial/types";
import { Scale } from "../Scale";
import { ScaleMeasurementParser } from "../ScaleMeasurementParser";
import type { ScaleMeasurement } from "../types";
import { BaudRates, DataBits, Parity, StopBits } from "../types";

export class SerialScale extends Scale {
  private events = new EventTarget();
  private helper: WebSerialHelper;
  private parser = new ScaleMeasurementParser(HardwareService.logger);

  constructor(port: SerialPort) {
    super(ConnectionType.serial);

    this.helper = new WebSerialHelper({
      device: port,
      commandTerminator: this.parser.commandTerminator,
      onBuffer: this.handleBuffer,
    });
  }

  get id(): string { return this.helper.id; }
  get isConnected(): boolean { return this.helper.connected; }
  get metadata() { return this.helper.metadata; }
  get persistence() { return DevicePersistence.singleton; }
  get productId(): number | undefined { return this.helper.productId; }
  get vendorId(): number | undefined { return this.helper.vendorId; }

  get options(): SerialConnectionOptions { return this.helper.options; }
  set options(config: SerialConnectionOptions) { this.helper.options = config; }

  //#region Private Methods
  // Sends a command to the scale and when a matcher is provided awaits a response
  private async sendCommand<T>(command: string, matcher?: { fn: (command: string) => T | null, waitMs?: number }): Promise<T | null> {
    const serialPromise = new Promise<T>((res, rej) => {
      if (matcher) {
        // timeout
        const timeout = setTimeout(() => {
          try {
            this.events.removeEventListener('output', handleCommand);
            rej(`send command timeout: ${command}`);
          }
          catch (e) { HardwareService.logger.error(e); }
        }, matcher.waitMs ?? 1000);

        const handleCommand = (event: Event) => {
          const evt = event as CustomEvent<string>;

          const command = evt.detail as string;

          // test command against
          const result = matcher.fn(command);
          if (result == null) return;

          this.events.removeEventListener('output', handleCommand);

          if (timeout != null) {
            clearTimeout(timeout)
          }
          res(result);
        }

        this.events.addEventListener('output', handleCommand)
      }

      // send command
      this.helper.write(command + this.parser.commandTerminator);
    });

    // promise
    return serialPromise;
  }
  //#endregion

  //#region Protected Methods
  attachEventListeners(): void {
    this.events.addEventListener('output', this.handleOutput);
  }

  detachEventListeners(): void {
    this.events.removeEventListener('output', this.handleOutput);
  }
  //#endregion

  //#region Public Methods
  async *autoConfigure(options ?: { waitMs ?: number }): AsyncGenerator<SerialConnectionOptions | null> {
    const configs: SerialConnectionOptions[] = [
      defaultSerialOptions, // ensure default is tested first
    ];

    for (const baudRate of BaudRates) {
      for (const dataBit of DataBits) {
        for (const stopBit of StopBits) {
          for (const parity of Parity) {
            const config: SerialConnectionOptions = {
              baudRate: baudRate,
              stopBits: stopBit,
              dataBits: dataBit,
              parity: parity as ParityType,
            };

            configs.push(config);
          }
        }
      }
    }

    // iterate through configurations
    for (const config of configs) {
      try {
        yield config;
        this.helper.options = config;

        await this.doDisconnect();
        await this.doConnect();

        try {
          const start = Date.now();
          const result = await this.requestMeasurement({ waitMs: options?.waitMs });
          const end = Date.now();
          if (result !== null) {
            HardwareService.logger.d('auto-configure success', {
              config, 
              'timing': `${end - start}ms`
            });
            return;
          }
        }
        catch (e) {
          // expected catch when reading times out
        }
      }
      catch (e) {
        /* no-op */
      }
    }
    yield null;
  }

  async doConnect(): Promise<boolean> {
    return this.helper.connect();
  }

  async doDisconnect(): Promise<boolean> {
    return this.helper.disconnect();
  }

  async doRevokePermission(): Promise<boolean> {
    this.helper.revokePermission();
    return true;
  }

  // must be arrow function to be bound to this
  handleBuffer = (buffer: string): string | boolean => {
    this.parser.parseDataBuffer(buffer).forEach(data => {
      this.events.dispatchEvent(new CustomEvent('output', { detail: data }))
    })

    if (buffer.endsWith(this.parser.commandTerminator)) {
      return true
    }
    else {
      const lastCommand = buffer.split(this.parser.commandTerminator).pop()
      if (lastCommand) {
        return lastCommand
      }
    }

    return false
  }

  // must be arrow function to bind this
  handleOutput = (event: Event): void => {
    const evt = event as CustomEvent<string>;
    const command = evt.detail as string;
    const measurement = this.parser.parseMeasurement(command)
    if (!measurement) return

    this.emitMeasurementEvent(measurement);
  }

  async modelName(): Promise<string | null> {
    return this.sendCommand('?TN', { fn: (cmd) => this.parser.parseModelName(cmd) });
  }

  async requestMeasurement(config ?: { waitMs ?: number }): Promise<ScaleMeasurement | null> {
    return this.sendCommand('Q', { fn: (cmd) => this.parser.parseMeasurement(cmd), waitMs: config?.waitMs });
  }

  async serialNumber(): Promise<string | null> {
    return this.sendCommand('?SN', { fn: (cmd) => this.parser.parseSerialNumber(cmd) });
  }

  async streamMode(enabled: boolean): Promise<void> {
    const cmd = enabled ? 'SIR' : 'C';
    this.sendCommand(cmd);
  }

  async write(command: string): Promise<void> {
    return this.helper.write(command);
  }
  //#endregion
}
