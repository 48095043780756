import { PeripheralServiceConfig, PeripheralType } from "@dutchie/capacitor-hardware";
import { Peripheral } from "../../index";
import { NativePeripheralService } from "../shared/NativePeripheralService";
import { NativeReceiptPrinter } from "./NativeReceiptPrinter";

export class NativeReceiptPrinterService extends NativePeripheralService<NativeReceiptPrinter> {
  constructor(config?: Partial<PeripheralServiceConfig>) {
    super(PeripheralType.receiptPrinter, 'NativeReceiptPrinterService', config)
  }

  async availableDevices(): Promise<NativeReceiptPrinter[]> {
    const result = await Peripheral.searchReceiptPrinters();
    return result.results.map((it) => new NativeReceiptPrinter(it));
  }
}
