import { ConnectionType, LabelPrinter, PeripheralMetadata } from '@dutchie/capacitor-hardware';
import { Peripheral, LabelPrinter as PeripheralPackageLabelPrinter } from '../../index';

export class NativeLabelPrinter extends LabelPrinter {
  private device: PeripheralPackageLabelPrinter;

  constructor(device: PeripheralPackageLabelPrinter) {
    super(ConnectionType.usb);
    this.device = device;
  }

  get id(): string { return this.device.id; }
  get isConnected(): boolean { return this.device.connected; }
  get metadata(): PeripheralMetadata { 
    return {
      vendorId: this.device.vendor.id
    };
  }
  get name(): string { return this.device.name; }
  get peripheralData(): PeripheralPackageLabelPrinter { return this.device as PeripheralPackageLabelPrinter }
  get vendorId(): number { return this.device.vendor.id }

  async doConnect(): Promise<boolean> {
    const result = await Peripheral.connectLabelPrinter({ id: this.id });
    return result.success;
  }

  async doDisconnect(): Promise<boolean> {
    const result = await Peripheral.disconnectLabelPrinter({ id: this.id });
    return result.success;
  }

  async print(bytes: Uint8Array): Promise<boolean> {
    const result = await Peripheral.printLabel({ id: this.id, bytes: Array.from(bytes) });
    return result.success;
  }
}
