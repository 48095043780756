import { Queue } from 'async-await-queue';

import { storage } from "../../../utils/index";
import { HardwareService } from "../../HardwareService";

import type { SerialConnectionOptions } from "./types";
import { defaultSerialOptions } from "./types";
import { PeripheralMetadata } from '../types';

export type WebSerialHelperParams = {
  device: SerialPort;
  commandTerminator: string;
  onBuffer: (buffer: string) => void;
}

export class WebSerialHelper {
  options: SerialConnectionOptions = defaultSerialOptions;
  config: WebSerialHelperParams;
  private keepReading = false;
  private reader: ReadableStreamDefaultReader | null = null;
  private writer: WritableStreamDefaultWriter<Uint8Array> | null = null;

  private get port(): SerialPort { return this.config.device }
  private get portInfo() { return this.port.getInfo() }
  private get storageKey(): string { return `hardware.${this.id}` }
  
  private listeningPromise: Promise<void> | null = null;

  constructor(config: WebSerialHelperParams) {
    this.config = config;

    const record = storage.getItem(this.storageKey);
    if (record != null) {
      try {
        this.options = JSON.parse(record) as SerialConnectionOptions;
      }
      catch (e) {
        HardwareService.logger.error(e);
      }
    }
  }

  get connected(): boolean { return this.listeningPromise != null }
  get id(): string { return `serial-${this.portInfo.usbVendorId}-${this.portInfo.usbProductId}`; }

  get metadata(): PeripheralMetadata {
    return {
      productId: this.portInfo.usbProductId,
      vendorId: this.portInfo.usbVendorId,
    }
  }
  /** @deprecated use metadata.productId */
  get productId(): number | undefined { return this.portInfo.usbProductId }
  /** @deprecated use metadata.vendorId */
  get vendorId(): number | undefined { return this.portInfo.usbVendorId }

  async connect(): Promise<boolean> {
    try {
      this.keepReading = true;
      if (this.connected) return true;

      await this.port.open(this.options);
      storage.setItem(this.storageKey, JSON.stringify(this.options))

      this.listeningPromise = this.listen();
      return true;
    }
    catch (e) {
      HardwareService.logger.error(e);
    }
    return false;
  }

  async disconnect(): Promise<boolean> {
    try {
      this.keepReading = false;
      if (!this.connected) return true;

      try {
        if (this.reader) {
          await this.reader.cancel();
          this.reader.releaseLock();
          this.reader = null;
        }
      }
      catch (e) {
        HardwareService.logger.e('Release reader error', e);
      }

      try {
        if (this.writer) {
          await this.writer.close();
          this.writer.releaseLock();
          this.writer = null;
        }
      }
      catch (e) {
        HardwareService.logger.e('Release writer error', e);
      }

      try {
        await this.port.close();
      }
      catch (e) {
        HardwareService.logger.e('failed to close port', e);
      }

      await this.listeningPromise;
      this.listeningPromise = null;

      return true;
    }
    catch (e) {
      HardwareService.logger.error(e);
    }
    return false;
  }

  async revokePermission(): Promise<boolean> {
    storage.removeItem(this.storageKey);
    await this.port.forget();
    return true;
  }

  private writeQueue = new Queue(1, 0);
  async write(bytes: Uint8Array | string): Promise<void> {
    const me = Symbol();

    try {
      await this.writeQueue.wait(me);

      if (!this.connected) return;

      if (typeof bytes === 'string') {
        bytes = new TextEncoder().encode(bytes);
      }

      if (this.writer == null || this.port?.writable?.locked != true) {
        this.writer = this.port.writable?.getWriter() ?? null;
        if (this.writer === null) throw Error('Unable to get writer')
      }

      if (this.writer) {
        await this.writer.write(bytes);
      }
    }
    finally {
      this.writeQueue.end(me);
    }
  }

  private async listen() {
    let buffer = ''

    while (this.port.readable && this.keepReading) {
      try {
        // store created reader for use on disconnect
        this.reader = this.port.readable.getReader();

        while (true) {
          const { value, done } = await this.reader.read();
          if (done) {
            // reader.cancel() was called by disconnect
            break;
          }

          if (value) {
            try {
              // Append to buffer
              const stringValue = new TextDecoder().decode(value)
              buffer += stringValue

              this.config.onBuffer(buffer);
            }
            catch (e) {
              HardwareService.logger.e('buffer error', e);
            }

            if (buffer.endsWith(this.config.commandTerminator)) {
              buffer = ''
            }
            else {
              const lastCommand = buffer.split(this.config.commandTerminator).pop()
              if (lastCommand) {
                buffer = lastCommand
              }
            }
          }
        }
      } catch (e) {
        HardwareService.logger.e('error reading port', e);
      }
    }
  }
}
