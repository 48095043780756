export type ScaleMeasurement = {
  header: ScaleMeasurementHeader
  value: number
  unit: ScaleMeasurementUnit
}

export enum ScaleMeasurementHeader {
  outOfRange = 0,
  stable = 1,
  stableCounting = 2,
  unstable = 3
}

export const parseScaleMeasurementHeader = (value: string): ScaleMeasurementHeader => { 
  switch(value) {
    case 'OL':
      return ScaleMeasurementHeader.outOfRange
    case 'G':
    case 'N':
    case 'SG':
    case 'ST':
    case 'WT':
      return ScaleMeasurementHeader.stable
    case 'QT':
      return ScaleMeasurementHeader.stableCounting
    case 'US':
    case '?G':
    case '?N':
      return ScaleMeasurementHeader.unstable
    default:
      throw new Error(`Unknown scale measurement header: ${value}`)
  }
}

export enum ScaleMeasurementUnit {
  carat = 0,
  count = 1,
  grain = 2,
  gram = 3,
  momme = 4,
  ounce = 5,
  pennyWeight = 6,
  percent = 7,
  pound = 8,
  tael = 9,
  troyOunce = 10
}

export const parseScaleMeasurementUnit = (value: string): ScaleMeasurementUnit => {
  switch(value) {
    case 'ct':
      return ScaleMeasurementUnit.carat
    case 'PC':
      return ScaleMeasurementUnit.count
    case 'GN':
      return ScaleMeasurementUnit.grain
    case 'g':
      return ScaleMeasurementUnit.gram
    case 'mom':
      return ScaleMeasurementUnit.momme
    case 'oz':
      return ScaleMeasurementUnit.ounce
    case 'dwt':
      return ScaleMeasurementUnit.pennyWeight
    case '%':
      return ScaleMeasurementUnit.percent
    case 'lb':
      return ScaleMeasurementUnit.pound
    case 'tl':
      return ScaleMeasurementUnit.tael
    case 'ozt':
      return ScaleMeasurementUnit.troyOunce
    default:
      throw new Error(`Unknown scale measurement unit: ${value}`)
  }  
}

export const scaleUnitDisplay = (unit: ScaleMeasurementUnit): string => {
  switch (unit) {
    case ScaleMeasurementUnit.carat: return 'ct'
    case ScaleMeasurementUnit.count: return 'PC'
    case ScaleMeasurementUnit.grain: return 'GN'
    case ScaleMeasurementUnit.gram: return 'g'
    case ScaleMeasurementUnit.momme: return 'mom'
    case ScaleMeasurementUnit.ounce: return 'oz'
    case ScaleMeasurementUnit.pennyWeight: return 'dwt'
    case ScaleMeasurementUnit.percent: return '"%'
    case ScaleMeasurementUnit.pound: return 'lb'
    case ScaleMeasurementUnit.tael: return 'tl'
    case ScaleMeasurementUnit.troyOunce: return 'ozt'
  }
}

export const BaudRates = [600, 1200, 2400, 4800, 9600, 19200];
export const StopBits = [1, 2];
export const DataBits = [7, 8];
export const Parity = ['even', 'odd', 'none'];
