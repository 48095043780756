export class Completer<R> {
  promise: Promise<R>;

  private _resolve?: (value: R | PromiseLike<R>) => void;
  private _reject?: (error?: any, stackTrace?: string) => void;
  private _isComplete = false;

  constructor() {
    this.promise = new Promise((res, rej) => {
      this._resolve = res;
      this._reject = rej;
    });
  }

  get isCompleted(): boolean {
    return this._isComplete;
  }

  complete(result: R) : void {
    this._isComplete = true;
    this._resolve?.(result);
  }

  error(error: any) : void {
    this._isComplete = true;
    this._reject?.(error);
  }
}
