import { PeripheralMetadata, PeripheralType } from '../../shared/index';
import { WebviewAppPeripheralData, webviewAppBridge } from '../../shared/webview-app-bridge/index';
import { Scale } from '../Scale';

export class WebviewAppScale extends Scale {
  private device: WebviewAppPeripheralData;
  constructor(device: WebviewAppPeripheralData) {
    super(device.connectionType);
    this.device = device;
  }

  get id(): string {
    return this.device.id;
  }
  get isConnected(): boolean {
    return this.device.connected;
  }
  get metadata(): PeripheralMetadata {
    return this.device.metadata;
  }
  get name(): string {
    return this.device.name;
  }

  async doConnect(): Promise<boolean> {
    return webviewAppBridge.connect(PeripheralType.scale, this.id);
  }

  async doDisconnect(): Promise<boolean> {
    return webviewAppBridge.disconnect(PeripheralType.scale, this.id);
  }
}
