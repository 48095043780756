import { Capacitor } from "@capacitor/core";
import { Peripheral, PeripheralService, PeripheralType } from "@dutchie/capacitor-hardware";
import { ConnectionStatusPayload, PeripheralData } from "../../definitions";

export abstract class NativePeripheralService<T extends Peripheral> extends PeripheralService<T> {
  constructor(peripheralType: PeripheralType, name: string, config?: Partial<PeripheralService<T>>) {
    super(peripheralType, { autoConnect: false, autoEnable: true, name }, config);
  }
  
  get devicesStatusEventName(): string { 
    return `on${this.typeName.replace(' ', '')}DevicesStatus`
  }

  get connectionStatusEventName(): string {
    return `on${this.typeName.replace(' ', '')}ConnectionStatus`
  }

  get isSupported(): boolean { return Capacitor.getPlatform() === 'android' }

  private handleConnectionStatusRef = this.handleConnectionStatus.bind(this);
  private handleConnectionStatus(event: Event) {
    // force api to refresh device
    const payload = event as any as ConnectionStatusPayload<PeripheralData>;
    this.serviceManager.invalidateDeviceId(payload.peripheral.id);
  }

  private handleDevicesStatusRef = this.handleDevicesStatus.bind(this);
  private handleDevicesStatus(_event: Event) { 
    // force api to refresh device list
    this.invalidate();
  }

  attachEventListeners(): void {
    window.addEventListener(this.connectionStatusEventName, this.handleConnectionStatusRef);
    window.addEventListener(this.devicesStatusEventName, this.handleDevicesStatusRef);
  }

  detachEventListeners(): void {
    window.removeEventListener(this.connectionStatusEventName, this.handleConnectionStatusRef);
    window.removeEventListener(this.devicesStatusEventName, this.handleDevicesStatusRef);
  }
}
