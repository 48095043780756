import type { PeripheralServiceConfig } from '../../shared/index';
import { PeripheralType } from '../../shared/index';
import { WebviewAppPeripheralData, WebviewAppPeripheralService, WebviewAppReceiptPrinterData } from '../../shared/webview-app-bridge/index';

import { WebviewAppReceiptPrinter } from './WebviewAppReceiptPrinter';

export class WebviewAppReceiptPrinterService extends WebviewAppPeripheralService<WebviewAppReceiptPrinter> {
  constructor(config?: Partial<PeripheralServiceConfig>) {
    super(
      PeripheralType.receiptPrinter,
      {
        autoConnect: false,
        autoEnable: true,
        name: 'WebviewAppReceiptPrinterService',
      },
      config,
    );
  }

  createDevice(info: WebviewAppPeripheralData): WebviewAppReceiptPrinter {
    return new WebviewAppReceiptPrinter(info as WebviewAppReceiptPrinterData);
  }
}
