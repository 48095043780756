import { PeripheralServiceConfig, PeripheralType } from '@dutchie/capacitor-hardware';
import { Peripheral } from '../../index';
import { NativePeripheralService } from "../shared/NativePeripheralService";
import { NativeScale } from "./NativeScale";

export class NativeScaleService extends NativePeripheralService<NativeScale> {
  constructor(config?: Partial<PeripheralServiceConfig>) {
    super(PeripheralType.scale, 'NativeScaleService', config);
  }

  async availableDevices(): Promise<NativeScale[]> {
    const result = await Peripheral.searchScales();
    return result.results.map((it) => new NativeScale(it));
  }
}
