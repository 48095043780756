import type { PeripheralServiceConfig } from '../../shared/index';
import { PeripheralType } from '../../shared/index';
import { WebviewAppPeripheralData, WebviewAppPeripheralService } from '../../shared/webview-app-bridge/index';

import { WebviewAppScale } from './WebviewAppScale';

export class WebviewAppScaleService extends WebviewAppPeripheralService<WebviewAppScale> {
  constructor(config?: Partial<PeripheralServiceConfig>) {
    super(
      PeripheralType.scale,
      {
        autoConnect: false,
        autoEnable: true,
        name: 'WebviewAppScaleService',
      },
      config,
    );
  }

  createDevice(info: WebviewAppPeripheralData): WebviewAppScale {
    return new WebviewAppScale(info);
  }
}
