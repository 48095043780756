import { PeripheralType } from '../../shared/index';
import { WebviewAppPeripheralService } from '../../shared/webview-app-bridge/index';

import { WebviewAppScanner } from './WebviewAppScanner';

export class WebviewAppScannerService extends WebviewAppPeripheralService<WebviewAppScanner> {
  constructor() {
    super(PeripheralType.scanner, {
      autoConnect: true,
      autoEnable: true,
      name: 'WebviewAppScannerService',
    });
  }

  createDevice(info: any): WebviewAppScanner {
    return new WebviewAppScanner(info);
  }
}
