import { HardwareService } from '../../HardwareService';
import { Peripheral, PeripheralSearchConfig, PeripheralSearchResponse, PeripheralService } from '../index';
import { webviewAppBridge } from './WebviewAppBridge';
import { webviewNamespace } from './constants';
import { WebviewAppPeripheralData } from './types';

export abstract class WebviewAppPeripheralService<T extends Peripheral> extends PeripheralService<T> {
  abstract createDevice(info: WebviewAppPeripheralData): T;

  get isSupported(): boolean {
    return !!(window as any)[webviewNamespace];
  }

  protected async availableDevices(): Promise<T[]> {
    return [];
  }

  async search(config?: Partial<PeripheralSearchConfig>): Promise<PeripheralSearchResponse<T>> {
    try {
      const results = await webviewAppBridge.search(this.peripheralType, config);
      const devices = results.map(result => this.createDevice(result));

      return {
        devices,
        newDevices: []
      };
    } catch (e) {
      HardwareService.logger.e(`Failed searching for webview ${this.typeName}`, e);
    }
    return {
      devices: [],
      newDevices: []
    };
  }

  async waitUntilReady(): Promise<boolean> {
    const result = await webviewAppBridge.waitUntilReady(this.typeName);
    if (result) {
      webviewAppBridge.sync(this.peripheralType);
    }
    return result;
  }
}
