import { useCallback, useEffect, useState } from 'react';

import { HardwareService } from '../HardwareService';

import type { Peripheral } from './Peripheral';
import type { PeripheralServiceManager } from './PeripheralServiceManager';
import type { DeviceStatusEvent, PeripheralFilter, PeripheralSearchConfig } from './types';

type DeviceObject<T extends Peripheral> = {
  device: T;
}

type PeripheralHook<T extends Peripheral> = {
  item: DeviceObject<T>;
  refresh: () => void;
}

export const usePeripheral = <T extends Peripheral>(peripheral: T, service: PeripheralServiceManager<T>): PeripheralHook<T> => {
  const [item, setItem] = useState<DeviceObject<T>>({ device: peripheral });
  const device = item.device;

  const refresh = useCallback(() => {
    const current = service.deviceById(device.id);
    if (!current) return;

    // Putting the device in an object forces state to update
    setItem({ device: current });
  }, [device.id, service]);

  useEffect(() => {
    const handleDeviceUpdated = (event: Event) => {
      const detail = (event as CustomEvent<DeviceStatusEvent>).detail;
      if (detail.device.id !== device.id) return;
      refresh();
    }

    service.addEventListener('device-status', handleDeviceUpdated)
    return () => {
      service.removeEventListener('device-status', handleDeviceUpdated)
    }
  }, [device]);

  return { item, refresh };
}

export type PeripheralsHook<T extends Peripheral> = {
  devices: T[];
  refresh: () => void;
  search: (props?: Partial<PeripheralSearchConfig>) => void;
}

export const usePeripherals = <T extends Peripheral>(props: {
  service: PeripheralServiceManager<T>,
  filter?: PeripheralFilter<T>,
  onDevicesAuthorized?: (devices: T[]) => void;
}): PeripheralsHook<T> => {
  const { service, filter } = props;

  const filterDevices = useCallback((devices: T[]): T[] => {
    if (!filter) return devices;
    return devices.filter(filter);
  }, [filter]);

  const [devices, setDevices] = useState<T[]>(filterDevices(service.devices));

  const refresh = () => setDevices(filterDevices(service.devices));
  const search = async (config?: Partial<PeripheralSearchConfig>) => {
    await service.search(config);
    refresh();
  }

  useEffect(() => {
    const handleDevicesUpdated = () => {
      const filteredDevices = filterDevices(service.devices);
      setDevices(filteredDevices);
    }

    service.addEventListener('devices-updated', handleDevicesUpdated)

    return () => {
      service.removeEventListener('devices-updated', handleDevicesUpdated)
    }
  }, [filterDevices, service]);

  useEffect(() => {
    const handleDevicesAuthorized = ({ detail }: CustomEvent<{ devices: T[] }>) => {
      if (detail.devices.length) {
        props.onDevicesAuthorized?.(detail.devices);
      }
    }

    service.addEventListener('devices-authorized', handleDevicesAuthorized);

    return () => service.removeEventListener('devices-authorized', handleDevicesAuthorized);
  }, [service]);

  return { devices, refresh, search };
}
