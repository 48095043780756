import { PeripheralMetadata, PeripheralType } from '../../shared/index';
import { WebviewAppReceiptPrinterData, webviewAppBridge } from '../../shared/webview-app-bridge/index';
import { ReceiptLanguage } from '../enums';
import { ReceiptPrinter } from '../ReceiptPrinter';

export class WebviewAppReceiptPrinter extends ReceiptPrinter {
  private device: WebviewAppReceiptPrinterData;
  constructor(device: WebviewAppReceiptPrinterData) {
    super(device.connectionType);
    this.device = device;
  }

  get id(): string {
    return this.device.id;
  }
  
  get isConnected(): boolean {
    return this.device.connected;
  }
  
  get language(): ReceiptLanguage {
    return this.device.language as ReceiptLanguage ?? super.language;
  }

  get metadata(): PeripheralMetadata {
    return this.device.metadata;
  }

  get name(): string {
    return this.device.name;
  }
  
  get vendorId(): number | undefined {
    return this.device.vendorId;
  }

  private async write(bytes: Uint8Array): Promise<boolean> {
    return webviewAppBridge.print(PeripheralType.receiptPrinter, this.id, bytes);  }

  async doConnect(): Promise<boolean> {
    return webviewAppBridge.connect(PeripheralType.receiptPrinter, this.id);
  }

  async doDisconnect(): Promise<boolean> {
    return webviewAppBridge.disconnect(PeripheralType.receiptPrinter, this.id);
  }

  async popCashDrawer(): Promise<boolean> {
    return webviewAppBridge.openCashDrawer(PeripheralType.receiptPrinter, this.id);
  }

  async print(bytes: Uint8Array): Promise<boolean> {
    return this.write(bytes);
  }
}
