
import { vendorForId } from "../../utils/index";
import { HardwareService } from "../HardwareService";

import type { PeripheralServiceManager } from "./PeripheralServiceManager";
import type { IPeripheral } from "./interfaces";
import { ConnectionType, connectionTypeDisplayName, DevicePersistence, PeripheralMetadata, PeripheralType, peripheralTypeName } from "./types";

export type PeripheralInfo = {
  id: string;
  name: string;
  isConnected: boolean;
  connectionType: string;
  type: string;
  productId?: number;
  vendorId?: number;
}

export abstract class Peripheral implements IPeripheral {
  private peripheralType: PeripheralType;
  
  constructor(peripheralType: PeripheralType, connectionType: ConnectionType) {
    this.peripheralType = peripheralType;
    this.connectionType = connectionType;
  }

  connectionType: ConnectionType;
  protected get connectionTypeName(): string { return connectionTypeDisplayName(this.connectionType); }

  protected get typeName(): string { return peripheralTypeName(this.peripheralType); }

  abstract get isConnected(): boolean
  abstract get id(): string;

  // ensure a value is available
  get metadata(): PeripheralMetadata {
    return {}
  }

  // Best attempt to identify this peripheral based on available information
  get name(): string { 
    if (this.metadata.model) {
      return this.metadata.model;
    }
    
    if(this.vendorId) {
      const vendor = vendorForId(this.vendorId, this.peripheralType);
      if(vendor) {
        return `${vendor.name} ${this.typeName}`;
      }
    }

    return `${this.typeName} ${this.id}`;
  }

  // Sserializable object for logging
  get info(): PeripheralInfo {
    return {
      id: this.id,
      name: this.name,
      type: this.typeName,
      isConnected: this.isConnected,
      connectionType: this.connectionTypeName,
      vendorId: this.vendorId,
      productId: this.productId,
    };
  }

  // Informs the service manager of whether to use the existing object (singleton) 
  // or the provided object (transient) as the source of truth for connection status. 
  get persistence(): DevicePersistence { return DevicePersistence.transient };

  /** @deprecated Moved to metadata.productId */
  get productId(): number | undefined { return undefined; }
  /** @deprecated Moved to metadata.vendorId */
  get vendorId(): number | undefined { return undefined; }
  
  abstract doConnect(): Promise<boolean>;  
  abstract doDisconnect(): Promise<boolean>;
  doRevokePermission(): Promise<boolean> { throw new Error('Method not implemented.'); }

  // Connect to and listen for events specific to this device.
  // This method will only be called once when initially added
  // to the device grid on discovery or reconnect.
  protected attachEventListeners(): void { /* no-op */ };

  // Disconnect from and stop listening for events specific to this device.
  // This method is called when the device is removed from the 
  // device grid on disconnect or when forgotten.
  protected detachEventListeners(): void { /* no-op */ };

  async connect(): Promise<boolean> {
    const result = await this.doConnect();
    this.serviceManager.invalidateDeviceId(this.id)
    return result;
  }

  async disconnect(): Promise<boolean> {
    const result = await this.doDisconnect();
    this.serviceManager.invalidateDeviceId(this.id)
    return result;
  }

  async dispose(): Promise<void> {
    if (this.isConnected) {
      await this.disconnect();
    }
    this.detachEventListeners();
  };

  async initialize(): Promise<void> {
    this.attachEventListeners();
  }

  async invalidate(): Promise<void> {
    this.serviceManager.invalidateDeviceId(this.id)
  }

  get serviceName(): string | undefined {
    return this.serviceManager.serviceNameById(this.id);
  }

  private get serviceManager(): PeripheralServiceManager<any> {
    switch(this.peripheralType) {
      case PeripheralType.labelPrinter: return HardwareService.labelPrinter;
      case PeripheralType.receiptPrinter: return HardwareService.receiptPrinter;
      case PeripheralType.scale: return HardwareService.scale;
      case PeripheralType.scanner: return HardwareService.scanner;
    }
  }
  
  // This method is only implemented when the service requires an additional step
  // to remove a known device. It is used by WebUSB to re-require device permission
  // which removes it from the results returned by search()
  async revokePermission(): Promise<boolean> {
    await this.doRevokePermission();
    this.serviceManager.removeDevice(this);
    return true;
  }
}
