import { useEffect, useState } from "react";

import { HardwareService } from "../HardwareService";
import type { PeripheralFilter, PeripheralSearchConfig } from "../shared/types";
import { usePeripherals } from "../shared/usePeripherals";

import type { Scale } from "./Scale";
import type { ScaleMeasurement } from "./types";

export type DeviceScaleMeasurement = {
  measurement: ScaleMeasurement;
  device: Scale
}

export type ScalesHook = {
  scales: Scale[];
  refresh: () => void;
  search: (props?: Partial<PeripheralSearchConfig>) => void;
  deviceMeasurement: DeviceScaleMeasurement | undefined;
}

export type ScalesHookProps = {
  filter?: PeripheralFilter<Scale>
  onMeasurement?: (deviceMeasurement: DeviceScaleMeasurement) => void
  onDevicesAuthorized?: (devices: Scale[]) => void
}

export const useScales = (props?: ScalesHookProps): ScalesHook => {
  const [deviceMeasurement, setDeviceMeasurement] = useState<DeviceScaleMeasurement | undefined>(undefined);

  const result = usePeripherals<Scale>({
    service: HardwareService.scale,
    filter: props?.filter,
    onDevicesAuthorized: props?.onDevicesAuthorized,
  });

  const handleMeasurement = (evt: Event) => {
    const event = evt as CustomEvent<DeviceScaleMeasurement>;
    setDeviceMeasurement(event.detail);
    props?.onMeasurement?.(event.detail)
  };

  useEffect(() => {
    HardwareService.scale.addEventListener('measurement', handleMeasurement);
    return () => {
      HardwareService.scale.removeEventListener('measurement', handleMeasurement);
    }
  }, []);
  
  return {
    scales: result.devices,
    refresh: result.refresh,
    search: result.search,
    deviceMeasurement,
  }
}
